<template>
	<div v-if="loading" />
	<div v-else class="flex flex-col w-full mx-auto">
		<hr />
		<div class="w-full flex px-4 sm:mx-auto justify-between py-4 sm:px-20">
			<div class="flex flex-col font-jakarta">
				<span class="text-wemotooGrey text-sm">
					{{ quantityText }}
				</span>
				<div class="flex items-center gap-2">
					<span class="text-base text-wemotooPrimary font-semibold">{{ `RM ${purchaseTicket.total}` }}</span>
					<span v-if="purchaseTicket.origTotal != purchaseTicket.total" class="text-[12px] text-neutral-50 font-medium line-through">
						{{ `RM ${purchaseTicket.origTotal}` }}
					</span>
				</div>
			</div>
			<ButtonImage
				:disabled="props.disabledButton"
				:title="title"
				classes="text-center w-[14%] min-w-[150px]"
				title-class="text-xl h-7"
				@click="props.onSubmit"
			/>
		</div>
	</div>
</template>

<script lang="ts" setup>
const props = defineProps({
	disabledButton: { type: Boolean, default: true },
	onSubmit: Function,
	title: String,
});
const purchaseTicketStore = usePurchaseTicketStore();
const { totalQuantity, purchaseTicket, loading } = storeToRefs(purchaseTicketStore);

const quantityText = computed(() => {
	return totalQuantity.value > 1 ? `${totalQuantity.value} entries` : `${totalQuantity.value} entry`;
});
</script>

<style></style>
